/* eslint-disable prefer-destructuring */
import { notification } from 'antd';
import { get } from 'lodash';
import {
    createContext, FC, useEffect, useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';
import { IExpressDeliveryContext } from './express-delivery.interface';
import { initialContext } from './express-delivery.initial';
import { getBusinessesWithED, getExpressDeliveries, getExpressDelivery } from '../../../api/express-delivery';
import { socketInstance } from '../../../context/SocketClientContext';
import socketEvents from '../../../const/socketEvent';
import { ISocketPayloadED } from '../../../interfaces/sockets.interface';
import { SocketActions } from '../../../const/socket-actions';
import { IGuideNotification } from '../../Guides/context/guides.interface';

export const ExpressDeliveryContext = createContext<IExpressDeliveryContext>(initialContext);

export const ExpressDeliveryProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);
    const [expressSelected, setExpressSelected] = useState<IExpressDelivery | null>(null);
    const [expressDeliveries, setExpressDeliveries] = useState<IExpressDelivery[]>(initialContext.expressDeliveries);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const [searchParams, setSearchParams] = useSearchParams();

    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);
    const startDate = searchParams.get('startDate') || initialContext.startDate;
    const endDate = searchParams.get('endDate') || initialContext.endDate;
    const workspaceUniqueID = searchParams.get('workspaceUniqueID') || '';
    const tab = searchParams.get('tab') || initialContext.tab;

    const location = useLocation();

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = status);
        params.startDate = startDate;
        params.endDate = endDate;
        workspaceUniqueID && (params.workspaceUniqueID = workspaceUniqueID);
        params.tab = tab;
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            skip: (page - 1) * pageSize,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();

        value.length === 0 ? delete params.status : (params.status = JSON.stringify(value));

        setSearchParams(params);
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        date[0] !== initialContext.startDate && (params.startDate = date[0]);
        date[1] !== initialContext.endDate && (params.endDate = date[1]);
        setSearchParams(params);
    };

    const handleWorkspaceUniqueID = (value: string) => {
        setSearchParams({ ...getParams(), workspaceUniqueID: value });
    };

    const fetchExpressDeliveries = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        getExpressDeliveries(params)
            .then(({ data: { data: response } }: any) => {
                const { data, total } = response;
                setExpressDeliveries(data);
                setTotal(total);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const fetchExpressDelivery = (id: string) => {
        setLoadingAction(true);

        getExpressDelivery(id)
            .then(({ data: { data } }: any) => setExpressSelected(data))
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    const fetchBusinessWithED = async () => {
        const response = await getBusinessesWithED();
        return response.data.data;
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const expressID = query.get('expressDeliveryId');
        if (expressSelected && expressID) {
            fetchExpressDelivery(expressID);
        }
    }, [location.pathname]);
    useEffect(() => {
        if (!socketInstance) return;

        const handleNotification = ({ payload }: ISocketPayloadED) => {
            const { data } = payload;

            const updateExpressDeliveries = (id: string, updates: Partial<IExpressDelivery>) => {
                setExpressDeliveries((prev) => {
                    const index = prev.findIndex((item) => item._id === id);
                    if (index !== -1) {
                        const updatedList = [...prev];
                        updatedList[index] = { ...updatedList[index], ...updates };
                        return updatedList;
                    }
                    return prev;
                });
            };

            switch (data.action) {
            case SocketActions.EXPRESS_DELIVERY_CREATE: {
                const index = expressDeliveries.findIndex((item) => item._id === data.expressDeliveryId);
                if (index === -1) {
                    setExpressDeliveries((prev) => [data as unknown as IExpressDelivery, ...prev]);
                }
                break;
            }

            case SocketActions.EXPRESS_DELIVERY_UPDATE: {
                updateExpressDeliveries(data.expressDeliveryId, { status: data.status });
                break;
            }

            case SocketActions.GUIDE_CREATE:
            case SocketActions.GUIDE_UPDATE: {
                (data as unknown as IGuideNotification).expressDeliveries.forEach((ed) => {
                    updateExpressDeliveries(ed._id, { status: ed.status });
                });
                break;
            }

            default:
                break;
            }
        };

        socketInstance.on(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);

        return () => {
            socketInstance.off(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);
        };
    }, [socketInstance, expressDeliveries]);

    return (
        <ExpressDeliveryContext.Provider
            value={{
                loading,
                loadingAction,
                expressDeliveries,
                expressSelected,
                total,
                paginator,
                startDate,
                endDate,
                searchParams,
                setLoading,
                fetchExpressDeliveries,
                onPageChange,
                handleStatus,
                handleDate,
                handleWorkspaceUniqueID,
                setExpressSelected,
                fetchBusinessWithED,
            }}
        >
            {children}
        </ExpressDeliveryContext.Provider>
    );
};
