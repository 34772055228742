import moment from 'moment';
import { IDispersion } from '../../../interfaces/dispersion.interface';

export const initialContext = {
    dispersions: [] as IDispersion[],
    dispersionsToPay: [] as IDispersion[],
    loading: false,
    total: 0,
    searchParams: {},
    statusString: '[]',
    paginator: {
        limit: 10,
        page: 1,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    currencyString: '[]',
    selectedRowKeys: [],
    availableWeeks: [] as string[],
    onPageChange: () => { },
    handleDispersionID: () => { },
    handleSearch: () => { },
    handleDate: () => { },
    handleStatus: () => { },
    handleCurrency: () => { },
    handleDownload: () => { },
    fetchDispersions: () => { },
    setSelectedRowKeys: () => { },
    setDispersionsToPay: () => { },
    handlePayMultiple: () => { },
    payMultipleDispersions: () => { },
    fetchAvailableWeeks: () => { },
    handleWeek: () => { },
};
