import { NOTIFICATION_DESCRIPTIONS } from '../const/issue';
import socketEvents from '../const/socketEvent';
import { IHandler } from '../interfaces/handler.interface';
import { ISocketPayload } from '../interfaces/socket-payload.interface';

export const handlerNotification = ({
    socket, notification, navigate, dispatch,
}: IHandler) => {
    socket.on(socketEvents.NOTIFICATION_SEND_TO_ADMIN, ({ payload }: ISocketPayload) => {
        const { data } = payload;

        if (data.type === 'ISSUE') {
            const { issueID: { issuePublicID, status, _id }, action } = data;

            let description;

            if (action === NOTIFICATION_DESCRIPTIONS.NEW_ISSUE) {
                description = `New issue has been created with ID: ${issuePublicID}`;
            } else if (action === NOTIFICATION_DESCRIPTIONS.UPDATE_ISSUE) {
                description = `Issue ID: ${issuePublicID} has been updated to ${status}`;
            } else {
                description = `Issue ID: ${issuePublicID} has ${NOTIFICATION_DESCRIPTIONS.NEW_MESSAGE}`;
            }

            notification.info({
                message: action,
                description,
                onClick: () => navigate && navigate(`/v2/issues?open=${issuePublicID}`),
            });
        }
    });
};
