import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Modal, notification } from 'antd';
import { get } from 'lodash';
import { IGuide } from '../../../interfaces/express-delivery.interface';
import { cancelGuide } from '../../../api/express-delivery';
import { GuidesContext } from '../context/guides.context';

interface Props {
    item: IGuide;
    setItemSelected: (item: IGuide) => void;
}

const { confirm } = Modal;

const TableMenu = ({ item, setItemSelected }: Props) => {
    const { fetchData } = useContext(GuidesContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);

    const cancelService = () => {
        confirm({
            title: 'Are you sure you want to cancel this guide?',
            okText: 'Continue',
            cancelText: 'Cancel',
            onOk() {
                setLoading(true);
                cancelGuide(item._id)
                    .then(() => {
                        notification.success({
                            message: 'Guide cancelled',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = get(error, 'response.data.message', '');
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
            onCancel() {
                if (!loading) return;
            },
            okButtonProps: {
                loading,
            },
        });
    };

    const OPTIONS = [
        {
            children: 'View detail',
            onClick: () => {
                navigate(`/v2/guide/${item._id}`);
            },
        },
        {
            children: 'Cancel Guide',
            onClick: () => cancelService(),
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup
                key="g"
                title="Actions"
            >
                {
                    OPTIONS.map((option, index) => (
                        <Menu.Item key={index} {...option} />
                    ))
                }
            </Menu.ItemGroup>
        </Menu>
    );
};

export default TableMenu;
