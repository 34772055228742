import { Select } from 'antd';

const options = [
    { value: 'createdAt', label: 'Date' },
    { value: 'customerName', label: 'Customer' },
    { value: 'driverName', label: 'Driver' },
    { value: 'deliveryProvider', label: 'Delivery Provider' },
    { value: 'businessName', label: 'Business' },
    { value: 'connection', label: 'Connection' },
    { value: 'elapsedTime', label: 'Time' },
    { value: 'orderAmount', label: 'Order' },
    { value: 'paymentMethod', label: 'Payment' },
    { value: 'driverTag', label: 'Tag' },
    { value: 'tip', label: 'Tip Amount' },
    { value: 'rating', label: 'Rating' },
    { value: 'carName', label: 'Vehicle Type' },
];

export const SelectColumns = ({ value, setColumns }: any) => {
    function handleOnChange(values: any) {
        setColumns(values);
        localStorage.setItem('columns', JSON.stringify(values));
    }

    return (
        <Select
            mode="multiple"
            style={{ width: '300px' }}
            placeholder="Select Columns"
            onChange={handleOnChange}
            options={options}
            maxTagCount="responsive"
            value={value}
        />
    );
};
