import { Space, Tag, Typography } from 'antd';
import { FC } from 'react';
import { IGuideEvent } from '../../../interfaces/express-delivery.interface';

const { Text } = Typography;

interface GuideEventCardProps {
    eventData: IGuideEvent;
}

const GuideEventCard: FC<GuideEventCardProps> = ({ eventData }) => (

    <Space direction="vertical">
        <Text>
            {eventData.description}
        </Text>
    </Space>
);

export default GuideEventCard;
