export enum EFrequency {
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY'
}
export enum EInvoiceStatus {
    PENDING = 'PENDING',
    CLOSED = 'CLOSED',
    ACTIVE = 'ACTIVE',
    PAYED = 'PAYED',
    EXPIRED = 'EXPIRED',
    UNCOLLECTIBLE = 'UNCOLLECTIBLE',
}
