import {
    Card,
    Divider,
    PageHeader,
    Space,
    Typography,
} from 'antd';
import ItemData from '../../ExpressDelivery/components/Item';

interface Props {
    header?: string;
    estimatedDeliveryTime: number;
    totalDistance: number;
    providerFee: number;
}

const { Title } = Typography;

const MetaData = ({
    header,
    estimatedDeliveryTime,
    totalDistance,
    providerFee,
}: Props) => {
    const metersToKm = (value: number): number => value / 1000;
    const secondsToMinutes = (value: number): number => value / 60;

    return (
        <Card
            style={{ height: '100%', width: '100%' }}
            title={header ? (
                <Space size={8}>
                    {header || 'Delivery Data'}
                </Space>
            ) : null}
        >
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <ItemData
                    label="Estimated Delivery Time"
                    value={`${secondsToMinutes(estimatedDeliveryTime).toFixed(2) || 0} min`}
                />
                <ItemData
                    label="Total Distance"
                    value={`${metersToKm(totalDistance) || 0} km`}
                />
                <ItemData
                    label="Provider Fee"
                    value={`${providerFee.toFixed(2)}` || 0.00}
                />
            </Space>
        </Card>

    );
};

export default MetaData;
