import {
    Button,
    Modal,
    Typography,
    Form,
    notification,
} from 'antd';
import { useContext, useState } from 'react';
import ChangeStatus from './ChangeStatus';
import { InvoicesContext } from '../context/invoices.context';
import { bulkInvoices } from '../../../api/invoices';

const { confirm } = Modal;
const { Title, Text } = Typography;

const BulkInvoiceStatus = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const { selectedRowKeys, fetchData } = useContext(InvoicesContext);

    const handleChangeStatus = () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure change status of this invoices?
                </Title>
            ),
            content: (
                <ChangeStatus
                    form={form}
                    multiple
                />
            ),
            onCancel: () => {},
            okButtonProps: { loading },
            onOk: () => {
                setLoading(true);
                const { status } = form.getFieldsValue();

                bulkInvoices(selectedRowKeys, status)
                    .then(() => {
                        notification.success({
                            message: 'Invoices status updated successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    return (
        <Button
            type="primary"
            onClick={handleChangeStatus}
        >
            Change Status
        </Button>

    );
};

export default BulkInvoiceStatus;
