import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';

export interface IExpressDeliveryContext {
    loading: boolean;
    loadingAction: boolean;
    expressDeliveries: IExpressDelivery[];
    expressSelected: IExpressDelivery | null;
    total: number;
    paginator: {
        limit: number;
        skip: number;
    },
    startDate: string,
    endDate: string,
    searchParams: any,
    handleStatus: (status: string) => void,
    handleDate: (date: any) => void,
    handleWorkspaceUniqueID: (workspaceUniqueID: string) => void,
    fetchExpressDeliveries: () => void;
    setLoading: (value: boolean) => void;
    onPageChange: (page: number, pageSize: number) => void,
    setExpressSelected: (express: IExpressDelivery | null) => void;
    fetchBusinessWithED: () => void;
}

export enum EStatus {
    READY_FOR_PICKUP = 'READY_FOR_PICKUP',
    ACCEPTED = 'ACCEPTED',
    ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP',
    WAY_TO_DELIVER = 'WAY_TO_DELIVER',
    ARRIVED_AT_DELIVERY = 'ARRIVED_AT_DELIVERY',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    CANCELLED_BY_DELIVERY_PROVIDER = 'CANCELLED_BY_DELIVERY_PROVIDER',
    CANCELLED_BY_BUSINESS = 'CANCELLED_BY_BUSINESS',
    PENDING = 'PENDING',
    NOT_DELIVERED = 'NOT_DELIVERED',
    PROVIDER_NOT_FOUND = 'PROVIDER_NOT_FOUND',
}

export interface IEDNotification {
    action: string;
    expressDeliveryId: string;
    expressNumericId: number;
    status: string;
    workspaceID: string;
    statusGuide: string;
    guideId: string;
}
