import {
    Divider,
    PageHeader,
    Space,
    Typography,
    Collapse,
    Card,
} from 'antd';
import { IGuide } from '../../../interfaces/express-delivery.interface';
import DeliveryData from '../../ExpressDelivery/components/DeliveryData';

const { Title } = Typography;
const { Panel } = Collapse;

interface Props {
    itemSelected: IGuide;
}

const Deliveries = ({ itemSelected }: Props) => {
    console.log(itemSelected);
    return (
        <Card
            style={{ height: '100%', width: '100%' }}
            title={(
                <Space size={8}>
                    Express Deliveries (Points)
                </Space>
            )}
        >
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <Collapse
                    bordered={false}
                    ghost
                >
                    {
                        itemSelected.expressDeliveries.map((item, index) => {
                            const customer = `${item.customerName} ${item.customerLastName}`;

                            return (
                                <Panel
                                    key={index}
                                    header={(
                                        <Title level={5} style={{ marginBottom: 0 }}>
                                            {`${index + 1}º Point: ${item.expressNumericId} to ${customer}`}
                                        </Title>
                                    )}
                                >
                                    <DeliveryData
                                        description={item.description || '-'}
                                        customerCountryCode={item.customerCountryCode || '-'}
                                        customerMobile={item.customerMobile || '-'}
                                        weight={item.weight || 0}
                                        isFragile={item.isFragile || false}
                                        volume={item.volume || '-'}
                                        deliveryLocation={{
                                            address: item.deliveryAddress || '-',
                                            coordinates: item.deliveryCoordinates || { type: 'point', coordinates: [0, 0] },
                                            references: item.deliveryReferences || '-',
                                        }}
                                        pickupLocation={{
                                            address: item.pickupAddress || '-',
                                            coordinates: item.pickupCoordinates || { type: 'point', coordinates: [0, 0] },
                                            references: item.pickupReferences || '-',
                                        }}
                                    />
                                </Panel>
                            );
                        })
                    }
                </Collapse>
            </Space>
        </Card>

    );
};

export default Deliveries;
