import { Col, Row } from 'antd';

import { IWorkspace, IWallet } from '../../../types/workspaces';
import InformationDetail from './InformationDetail';
import SettingsDetail from './SettingsDetail';

interface IGeneralDetailContent {
    workspace: IWorkspace | any
    wallet: IWallet | any
    setWallet: () => any
}

const GeneralDetailContent = ({ workspace, wallet, setWallet }: IGeneralDetailContent) => (
    <Row gutter={[24, 24]} style={{ width: '100%' }}>
        <Col xs={24} xl={12}>
            <InformationDetail
                createdAt={workspace?.createdAt}
                ownerEmail={workspace?.ownerEmail}
                currency={workspace?.currency}
                country={workspace?.country}
                payment={workspace?.payment}
                isDeleted={workspace?.isDeleted}
                isSuspended={workspace?.isSuspended}
                suspendedMessage={workspace?.suspendedMessage}
                plan={workspace?.accountingPlan}
                workspaceId={workspace?._id}
                deletedAt={workspace?.deletedAt}
                name={workspace?.name}
                mrrBusinessValue={workspace?.mrrBusinessValue}
            />
        </Col>

        <Col xs={24} xl={12}>
            <SettingsDetail
                workspaceId={workspace?._id}
                masterKey={workspace?.masterKey}
                requiresProofOfDelivery={workspace?.requiresProofOfDelivery}
            />
        </Col>
    </Row>
);

export default GeneralDetailContent;
