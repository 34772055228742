import moment from 'moment';
import { memo } from 'react';
import {
    Button,
    Card,
    Col, Divider, Row, Space, Tooltip, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa';

interface Props {
    createdAt: Date;
    customerName?: string;
    customerLastName?: string;
    businessName: string;
    businessID: string;
    workspaceUniqueID?: string | null;
    workspaceID: string;
    workspaceName: string;
    deliveryProvider?: string;
    driverName?: string;
    driverMobile?: string;
}

const { Text } = Typography;
const Information = ({
    createdAt,
    customerName,
    customerLastName,
    businessName,
    businessID,
    workspaceUniqueID,
    workspaceID,
    workspaceName,
    deliveryProvider,
    driverName,
    driverMobile,
}: Props) => {
    const navigate = useNavigate();

    return (
        <Card
            style={{ height: '100%', width: '100%' }}
            title={(
                <Space size={8}>
                    Information
                </Space>
            )}
        >
            <Row
                align="middle"
                justify="start"
                gutter={[32, 24]}
                style={{ width: '100%' }}
            >
                <Col className="booking__info">
                    <Text className="booking__info--text-label">
                        Created at
                    </Text>
                    <Text className="booking__info--text-value">
                        {moment(createdAt).format('DD MMM HH:mm')}
                    </Text>
                </Col>

                {
                    (customerName || customerLastName) && (
                        <>
                            <Divider
                                type="vertical"
                                style={{ height: 40 }}
                            />
                            <Col className="booking__info">
                                <Text className="booking__info--text-label">
                                    Customer
                                </Text>
                                <Text className="booking__info--text-value">
                                    {`${customerName} ${customerLastName}`}
                                </Text>
                            </Col>
                        </>
                    )
                }

                <Divider
                    type="vertical"
                    style={{ height: 40 }}
                />

                <Col className="booking__info">
                    <Text className="booking__info--text-label">
                        Business name
                    </Text>
                    <Text
                        className="booking__info--text-value link"
                        onClick={() => navigate(`/v2/business/${businessID}`)}
                    >
                        {businessName || 'Not available'}
                    </Text>
                </Col>

                <Divider
                    type="vertical"
                    style={{ height: 40 }}
                />

                <Col className="booking__info">
                    <Text className="booking__info--text-label">
                        Workspace
                    </Text>
                    <Text
                        className="booking__info--text-value link"
                        onClick={() => navigate(`/v2/workspaces/${workspaceID}`)}
                    >
                        {`${workspaceName} ${workspaceUniqueID || ''}` || 'Not available'}
                    </Text>
                </Col>

                <Divider
                    type="vertical"
                    style={{ height: 40 }}
                />

                <Col className="booking__info">
                    <Text className="booking__info--text-label">
                        Delivery provider
                    </Text>
                    <Text className="booking__info--text-value">
                        {deliveryProvider}
                    </Text>
                </Col>

                <Divider
                    type="vertical"
                    style={{ height: 40 }}
                />

                <Col className="booking__info">
                    <Text className="booking__info--text-label">
                        Driver
                    </Text>
                    <Space size={4}>
                        <Text className="booking__info--text-value">
                            {driverName || 'Not assigned'}
                        </Text>
                        {
                            driverMobile && (
                                <Tooltip title={driverMobile}>
                                    <Button
                                        type="link"
                                        size="small"
                                        icon={<FaPhone />}
                                    />
                                </Tooltip>
                            )
                        }
                    </Space>
                </Col>
            </Row>
        </Card>

    );
};
export default memo(Information);
