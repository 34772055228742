import { Table, Typography, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { InitialColumns } from './initialColumns';
import { CashDeliveryContext } from '../context/cashDeliveryContext';

import Filters from './Filters';
import MultiplePay from './MultiplePay';

const { Title } = Typography;

interface Props {
    params: any;
}

const TableDispersions = ({ params }: Props) => {
    const INITIAL_COLUMNS: any = InitialColumns();

    const {
        loading,
        dispersions,
        availableWeeks,
        dispersionsToPay,
        total,
        paginator,
        onPageChange,
        fetchDispersions,
        fetchAvailableWeeks,
        searchParams,
        statusString,
        startDate,
        endDate,
        currencyString,
        selectedRowKeys,
        setSelectedRowKeys,
        handleDispersionID,
        handleSearch,
        handleStatus,
        handleWeek,
        handleCurrency,
        handlePayMultiple,
        setDispersionsToPay,
        payMultipleDispersions,
        handleDownload,
    } = useContext(CashDeliveryContext);

    const { limit, page } = paginator;
    const { dispersionUniqueID, search, week } = searchParams;

    const defaultDispersionUniqueID = params.get('dispersionUniqueID');
    const defaultSearch = params.get('search');
    const defaultWeek = params.get('week');

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        fetchDispersions();
    }, [paginator, dispersionUniqueID, search, defaultDispersionUniqueID, defaultSearch, startDate, endDate, statusString, currencyString, defaultWeek]);

    useEffect(() => {
        fetchAvailableWeeks();
    }, [defaultSearch, defaultDispersionUniqueID, statusString]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            {
                dispersionsToPay.length > 0 && (
                    <MultiplePay
                        open={dispersionsToPay.length > 0}
                        onClose={() => setDispersionsToPay([])}
                        dispersionsToPay={dispersionsToPay}
                        payMultipleDispersions={payMultipleDispersions}
                    />
                )
            }
            <Filters
                availableWeeks={availableWeeks}
                defaultDispersionUniqueID={defaultDispersionUniqueID}
                defaultSearch={defaultSearch}
                searchParams={searchParams}
                hasSelected={selectedRowKeys.length > 1}
                handleDispersionUniqueID={handleDispersionID}
                handleSearch={handleSearch}
                handleStatus={handleStatus}
                handleCurrency={handleCurrency}
                handlePayMultiple={handlePayMultiple}
                handleDownload={handleDownload}
                handleWeek={handleWeek}
            />
            <Table
                title={
                    () => (
                        <Title level={4}>
                            Dispersions table
                        </Title>
                    )
                }
                rowSelection={rowSelection}
                loading={loading}
                columns={INITIAL_COLUMNS}
                dataSource={dispersions}
                pagination={{
                    total,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1500 }}
                rowKey="_id"
            />
        </Space>
    );
};

export default TableDispersions;
