import { notification } from 'antd';
import { get } from 'lodash';
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { IContext } from './context.interface';
import { initialContext } from './context.initial';
import { IGuide, IGuideEvent } from '../../../interfaces/express-delivery.interface';
import { getGuide } from '../../../api/express-delivery';
import { getEventsByGuideId } from '../../../api/guides';
import { socketInstance } from '../../../context/SocketClientContext';
import socketEvents from '../../../const/socketEvent';
import { SocketActions } from '../../../const/socket-actions';
import { ISocketPayloadGuide } from '../../../interfaces/sockets.interface';
import { EStatus } from '../../ExpressDelivery/context/express-delivery.interface';
import { IGuideNotification } from '../../Guides/context/guides.interface';

export const GuideDetailsContext = createContext<IContext>(initialContext);
export const GuideDetailsProvider: FC = ({ children }) => {
    const { guideID } = useParams();

    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [guide, setGuide] = useState<IGuide | null>(initialContext.guide);

    const fetchGuide = async (id: string) => {
        setLoading(true);
        getGuide(id)
            .then(({ data: { data } }: any) => {
                setGuide(data);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!guideID) return;
        fetchGuide(guideID);
    }, [guideID]);

    useEffect(() => {
        if (!socketInstance) return;

        const handleGuideUpdate = (socketData: IGuideNotification) => {
            if (socketData.guideId === guideID) {
                setGuide((prevGuide) => ({
                    ...prevGuide,
                    ...socketData as unknown as IGuide,
                }));
            }
        };

        const handleExpressDeliveryUpdate = (socketData: { status: EStatus }) => {
            if (socketData.status === EStatus.ARRIVED_AT_DELIVERY) {
                setGuide((prevGuide) => (prevGuide ? { ...prevGuide, status: socketData.status } : prevGuide));
            }
        };

        const handleNotification = ({ payload }: ISocketPayloadGuide) => {
            const { data: socketData } = payload;

            switch (socketData.action) {
            case SocketActions.GUIDE_UPDATE:
                handleGuideUpdate(socketData);
                break;
            case SocketActions.EXPRESS_DELIVERY_UPDATE:
                handleExpressDeliveryUpdate(socketData as { status: EStatus });
                break;
            default:
                break;
            }
        };

        socketInstance.on(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);

        return () => {
            socketInstance.off(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);
        };
    }, [guideID, socketInstance]);

    return (
        <GuideDetailsContext.Provider
            value={{
                loading,
                guide,
            }}
        >
            {children}
        </GuideDetailsContext.Provider>
    );
};
