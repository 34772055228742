import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import {
    Input,
    Col,
    Modal,
    Row,
    Table,
    notification,
    Typography,
} from 'antd';
import { BiTrash } from 'react-icons/bi';
import {
    getMRRTransactions,
} from '../../../api/payments';
import { IMRRTransaction } from '../../Invoices/context/invoices.interface';
import Businesses from './Businesses';

const { Text } = Typography;

const MRRTransactions = ({ invoice, onClose }: any) => {
    const [loading, seLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<any[]>([]);
    const allTransactions = useRef<IMRRTransaction[]>([]);

    useEffect(() => {
        if (!invoice) return;
        seLoading(true);
        getMRRTransactions(invoice._id)
            .then(({ data }: any) => {
                allTransactions.current = data.data.data;
                setTransactions(data.data.data);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || 'Error fetching transactions';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => seLoading(false));
    }, [invoice]);

    const isOpen = invoice;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            width: 100,
        },
        {
            title: 'Total business',
            dataIndex: 'business',
            key: 'business',
            width: 124,
            render: (item: string[]) => item.length || 0,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 156,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            width: 200,
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 100,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 140,
            render: (amount: number) => amount.toFixed(2),
        },
        /* {
            dataIndex: '_id',
            key: 'actions',
            render: (transactionId: any, transaction: IInvoiceTransaction) => (
                <Button
                    type="text"
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: `Do you want to delete this transaction? ${transaction.transactionId}`,
                            onOk: async () => {
                                try {
                                    const transaction = await deleteTransaction(transactionId);
                                    if (transaction) {
                                        setTransactions((transactions) => transactions.filter((t) => t._id !== transactionId));
                                        notification.success({
                                            message: 'Transaction deleted',
                                        });
                                    }
                                } catch (error) {
                                    notification.error({
                                        message: 'Error deleting transaction',
                                    });
                                }
                            },
                        });
                    }}
                >
                    <BiTrash />
                </Button>
            ),
            width: 60,
        }, */
    ];

    const handleClose = () => {
        const amount = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
        const newInvoice = {
            ...invoice,
            amount,
        };
        onClose(newInvoice);
    };

    const handleSearch = (value: string) => {
        if (!value) {
            return setTransactions(allTransactions.current);
        }
        const filtered = allTransactions.current.filter(
            (transaction) => transaction.transactionId.toString().includes(value) || transaction.transactionId.toString().includes(value),
        );
        setTransactions(filtered);
    };

    return (
        <Modal
            title={`MRR Transactions of invoice: ${invoice?.invoiceId || ''}`}
            open={isOpen}
            width="100%"
            onOk={handleClose}
            onCancel={handleClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <Row justify="end" gutter={[10, 20]}>
                <Col>
                    <Input.Search
                        placeholder="Transaction"
                        onSearch={handleSearch}
                    />
                </Col>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={transactions}
                        bordered
                        scroll={{ x: 1000 }}
                        rowKey="_id"
                        size="small"
                        expandable={{
                            rowExpandable: (record) => !!record.business.length,
                            expandedRowRender: (record) => (
                                <Businesses
                                    invoiceId={record.invoiceID}
                                    businessIds={record.business}
                                />
                            ),
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default MRRTransactions;
