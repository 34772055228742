/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
import {
    Avatar, Col, DatePicker, Dropdown, Input, Menu, notification, PageHeader, Row, Space, Table, Tag, Tooltip, Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    AiFillCamera, AiFillCloseCircle, AiOutlineFileWord, AiOutlineStar,
} from 'react-icons/ai';
import {
    BsCash, BsCreditCard2Back, BsFillPinFill, BsThreeDots,
} from 'react-icons/bs';
import { IoLocationSharp } from 'react-icons/io5';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { isEqual, isUndefined, set } from 'lodash';

import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getBookings, SMR_URL, startSearch } from '../api/booking';
import { BookingType } from '../types/booking';
import { endSearch } from '../api/deliveryProviders';
import {
    openModal, setChangeStatus, setDriverManuallylId, setRestartSearchId, setRidesAddComment,
} from '../actions/modals';
import {
    AssigningDriverManually, CancelService, ChangeStatusModal, RestartSearchModal, CommentModal,
} from '../components/Modals';
import { SelectInput } from '../components/SelectInput';
import {
    getStatusChipClass, getProofTitle, getProofColor, snapshotWarningServiceRequestData,
} from '../utils/bookings';
import { DropDownButtonRefresh } from '../components/DropDownButtonRefresh';
import { getColorDriverConnection } from '../utils/driver';
import { SelectColumns } from '../components/SelectColumns';
import { ReportIssue } from '../components/Modals/ReportIssue/ReportIssue';
import { ModalTypes } from '../types/modals';
import { IMAGES } from '../const/images';
import { STATUSES } from '../const/booking';
import socketEvents from '../const/socketEvent';
import { socketInstance } from '../context/SocketClientContext';
import { ORIGIN } from '../api';

const { RangePicker }: any = DatePicker;
const { Search } = Input;
const { Text } = Typography;

export const Bookings = () => {
    const initialStartDate = moment().startOf('day').toISOString();
    const initialEndDate = moment().endOf('day').toISOString();
    const [loading, setLoading] = useState(true);
    const [corporateBookings, setCorporateBookings] = useState<BookingType[]>([]);
    const [bookingFromSocket, setBookingFromSocket] = useState<any>();
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);
    const [columns, setColumns] = useState([
        'createdAt', 'customerName', 'driverName', 'deliveryProvider',
        'businessName', 'connection', 'elapsedTime',
        'orderAmount', 'paymentMethod', 'driverTag',
    ]);
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';
    const startDate = searchParams.get('startDate') || initialStartDate;
    const endDate = searchParams.get('endDate') || initialEndDate;
    const currentStatusString = searchParams.get('currentStatus') || '[]';
    const currentStatus = JSON.parse(currentStatusString);
    const { deliveries, loading: dpLoading } = useSelector((state: any) => state.deliveries);
    const [limitDatePicker, setLimitDatePicker] = useState(false);

    const initialColumns: any = {
        bookingNumericId: {
            title: 'Service ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            width: 140,
            render: (bookingNumericId: string, booking: any) => (
                <Link style={{ color: '#08d108' }} to={`/v2/booking?id=${booking._id}`}>
                    {bookingNumericId}
                </Link>
            ),
        },
        createdAt: {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (createdAt: string) => (
                <div style={{ fontSize: 12, textAlign: 'center' }}>
                    <div>
                        {moment(createdAt).format('DD/MM/YY')}
                    </div>
                    <div>
                        {moment(createdAt).format('HH:mm')}
                    </div>
                </div>
            ),
        },

        proofOfDelivery: {
            title: 'Proof of Delivery',
            dataIndex: 'proofOfDeliveryType',
            key: 'proofOfDeliveryType ',
            width: 100,
            render: (proofOfDeliveryType : string, booking: any) => (
                <Tooltip placement="topLeft" title={getProofTitle(proofOfDeliveryType)}>
                    {proofOfDeliveryType === 'PHOTO' && <AiFillCamera color={getProofColor(booking.isProofOfDeliveryApproved)} size={20} /> }
                    {proofOfDeliveryType === 'CODE' && <AiOutlineFileWord color={getProofColor(booking.isProofOfDeliveryApproved)} size={20} /> }
                    {(!proofOfDeliveryType || proofOfDeliveryType === 'NONE') && <AiFillCloseCircle color="#FF0000" size={20} />}
                </Tooltip>
            ),
        },
        customerName: {
            title: 'Customer',
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
        },
        driverName: {
            title: 'Driver',
            dataIndex: 'driverName',
            key: 'driverName',
            ellipsis: true,
            render: (driverName: string, booking: any) => {
                const isOnBatch = !isUndefined(booking.isActiveOnBatch) && !booking.isActiveOnBatch;
                return (
                    <Space size={5}>
                        {isOnBatch && (
                            <Tooltip title="Driver has another booking in progress">
                                <Avatar
                                    src={IMAGES.icons.batch}
                                    shape="circle"
                                    size={20}
                                />
                            </Tooltip>
                        )}
                        {!booking.driver ? driverName : (
                            <Link to={`/v2/driver-details/${booking.driver._id}`}>{driverName}</Link>
                        )}
                    </Space>
                );
            },
        },
        deliveryProvider: {
            title: 'DP',
            key: 'deliveryProvider',
            dataIndex: 'deliveryProvider',
            width: 65,
            render: (provider: string) => {
                const delivery = dpLoading ? {} : deliveries.find((delivery: any) => delivery.keyName === provider);
                return (
                    <Tooltip title={delivery?.name || ''}>
                        <Avatar src={delivery?.image || ''} />
                    </Tooltip>
                );
            },
        },
        businessName: {
            title: 'Business',
            dataIndex: 'businessName',
            key: 'businessName',
            ellipsis: true,
        },
        connection: {
            title: 'Connection',
            dataIndex: '',
            key: '_',
            width: 120,
            render: (booking: any) => {
                const driver = booking.driver?.driverID || {};
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <RiBattery2ChargeFill color={getColorDriverConnection(driver, 'savingBattery')} size={18} />
                        <BsFillPinFill color={getColorDriverConnection(driver, 'locationForeground')} size={18} />
                        <IoLocationSharp color={getColorDriverConnection(driver, 'locationBackground')} size={18} />
                    </div>
                );
            },
        },
        elapsedTime: {
            title: 'Time',
            dataIndex: 'elapsedTime',
            key: 'elapsedTime',
            width: 120,
            ellipsis: true,
            render: (createdAt: Date, record: BookingType) => {
                const booking = new BookingType(record);
                const minutes: any = booking.getMinutes();
                const isGreen = minutes < 6 || booking.statusText === 'COMPLETED';
                const color = isGreen ? '#08d108' : minutes < 11 ? 'orange' : 'red';
                return (
                    <div style={{ color }}>
                        {`${minutes} min`}
                    </div>
                );
            },
        },
        orderAmount: {
            title: 'Order',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            width: 120,
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        paymentMethod: {
            title: 'Paym.',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            width: 80,
            render: (paymentMethod: string) => (
                <Tooltip placement="topLeft" title={paymentMethod === 'CASH' ? 'Efectivo' : 'Tarjeta'}>
                    {paymentMethod === 'CASH' ? <BsCash color="#08d108" size={20} /> : <BsCreditCard2Back color="#00beff" size={20} />}
                </Tooltip>
            ),
        },
        driverTag: {
            title: 'Tag',
            dataIndex: 'driverTag',
            key: 'driverTag',
        },
        rating: {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            width: 130,
            render: (rating: number, booking: any) => (
                <div>
                    { rating ? (
                        <Tooltip title={booking?.review || ''}>
                            {
                                [...Array(5)].map((_, index) => (
                                    <AiOutlineStar
                                        key={index}
                                        size={17}
                                        style={{
                                            color: index < rating ? '#FFC107' : '#E4E4E4',
                                            marginRight: 2,
                                        }}
                                    />
                                ))
                            }
                        </Tooltip>
                    ) : (
                        <div>
                            <span className="bookings__table--col-text">
                                NA
                            </span>
                        </div>
                    )}
                </div>
            ),
        },
        tip: {
            title: 'Tip Amount',
            dataIndex: 'priceBreakUp',
            key: 'tip',
            width: 120,
            render: (priceBreakUp: any, booking: any) => `$${(priceBreakUp.tip || 0).toFixed(2)} ${booking.currency}`,
        },
        carName: {
            title: 'Vehicle Type',
            dataIndex: 'carName',
            key: 'carName',
            render: (carName: 'LITE' | 'BIKE') => (
                <Text>
                    {carName || 'LITE'}
                </Text>
            ),
        },
        status: {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
        actions: {
            title: '',
            dataIndex: 'statusText',
            key: 'actions',
            width: 60,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <BookingMenu
                            booking={record}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    };

    const getBookingsData = () => {
        setLoading(true);
        const { limit, skip } = paginator;
        getBookings({
            limit, skip, startDate, endDate,
        }, search, currentStatus)
            .then(({ data }) => {
                const { data: bookingData } = data;
                setCorporateBookings(bookingData.data);
                setTotal(bookingData.total);
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getBookingsData();
    }, [paginator, startDate, endDate, search, currentStatusString]);

    useEffect(() => {
        const columns = localStorage.getItem('columns') || '[]';
        setColumns(JSON.parse(columns));
    }, []);

    const getParams = () => {
        const query: any = {};
        search && (query.search = search);
        currentStatus.length && (query.currentStatus = currentStatus);
        startDate !== initialStartDate && (query.startDate = startDate);
        endDate !== initialEndDate && (query.endDate = endDate);
        return query;
    };

    const getColumnsByLabel = (columns: string[]) => {
        const columnsLabels = ['bookingNumericId', ...columns, 'status', 'proofOfDelivery', 'actions'];

        const filteredColumns = Object.keys(initialColumns).filter((column) => columnsLabels.includes(column));

        const newColumns = filteredColumns.map((column) => initialColumns[column]);

        return newColumns;
    };

    const columnsSelected = getColumnsByLabel(columns);

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        date[0] !== initialStartDate && (params.startDate = date[0]);
        date[1] !== initialEndDate && (params.endDate = date[1]);
        setSearchParams(params);
    };

    const handleCurrentStatus = (status: any) => {
        const { currentStatus, ...params } = getParams();
        status.length && (params.currentStatus = JSON.stringify(status));
        setSearchParams(params);
    };

    const handleSearch = (query: string) => {
        setSearchParams({ ...getParams(), search: query });
    };

    useEffect(() => {
        socketInstance.on(socketEvents.BOOKING_SEND_TO_ADMIN, (payload) => {
            setBookingFromSocket(payload);
        });

        return () => {
            socketInstance.off(socketEvents.BOOKING_SEND_TO_ADMIN);
        };
    }, []);

    const bookings = useMemo(() => {
        const snapshot = snapshotWarningServiceRequestData({
            bookingFromSocket,
            bookings: corporateBookings,
            skip: paginator.skip,
        });

        if (isEqual(snapshot, corporateBookings)) return corporateBookings;

        setCorporateBookings(snapshot);

        return snapshot;
    }, [corporateBookings, bookingFromSocket, paginator.skip]);

    return (
        <div>
            <PageHeader
                title="Corporate Service Requests"
            />
            <Row justify="end" gutter={16}>
                <Col>
                    <Tooltip title="El rango de fechas debe ser de 31 días o menos. Por favor, seleccione un rango menor" open={limitDatePicker}>
                        <RangePicker
                            onChange={(dates: [Moment | null, Moment | null]) => {
                                if (dates && dates[0] && dates[1]) {
                                    const [startDate, endDate] = dates;

                                    if (endDate.diff(startDate, 'days') > 31) {
                                        setLimitDatePicker(true);
                                        return;
                                    }

                                    handleDate([
                                        startDate.startOf('day').toISOString(),
                                        endDate.endOf('day').toISOString(),
                                    ]);
                                } else {
                                    handleDate([
                                        initialStartDate,
                                        initialEndDate,
                                    ]);
                                }
                                setLimitDatePicker(false);
                            }}
                        />
                    </Tooltip>

                </Col>
                <Col>
                    <SelectColumns value={columns} setColumns={setColumns} />
                </Col>
                <Col>
                    <SelectInput currentStatus={currentStatus} setCurrentStatus={handleCurrentStatus} />
                </Col>
                <Col>
                    <Search defaultValue={search} onSearch={handleSearch} enterButton />
                </Col>
                <Col>
                    <DropDownButtonRefresh getBookingsData={getBookingsData} />
                </Col>
            </Row>
            <br />
            <div>
                <Table
                    loading={loading}
                    columns={columnsSelected}
                    dataSource={[...bookings]}
                    rowClassName={(record: any, index) => (record.firstTimeOrder ? 'table-row-warning' : 'booking-row')}
                    bordered
                    scroll={{ x: 1500 }}
                    pagination={{
                        total,
                        current: (paginator.skip / paginator.limit) + 1,
                        pageSize: paginator.limit,
                        onChange: onPageChange,
                        showSizeChanger: true,
                    }}
                    rowKey="_id"
                />
                <AssigningDriverManually />
                <ChangeStatusModal />
                <CommentModal />
                <RestartSearchModal />
                <CancelService />
            </div>
            <ReportIssue />
        </div>
    );
};
interface BookingMenuProps {
  booking: BookingType,
}

const BookingMenu = ({ booking }: BookingMenuProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isOnHold = booking.statusText === STATUSES.ON_HOLD.TEXT;

    const userID = useSelector((state: RootStateOrAny) => state.auth._id);

    const menu = [
        {
            children: 'Assign Picker Manually',
            onClick: () => {
                dispatch(setDriverManuallylId(booking));
            },
        },
        {
            children: 'Change Status',
            onClick: () => {
                dispatch(setChangeStatus(booking));
            },
        },
        ...(isOnHold ? [{
            children: 'Start Search',
            onClick: () => {
                startSearch(booking._id)
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch((err: any) => {
                        const error = err.response?.data?.message;
                        notification.error({
                            message: 'Error',
                            description: error,
                        });
                    });
            },
        }] : []),
        {
            children: 'End Search',
            onClick: () => {
                endSearch(booking._id, { isAdmin: true })
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch((err) => notification.error({
                        message: 'Opps!',
                        description: 'An Error ocurred',
                    }));
            },
        },
        {
            children: 'Share My Ride',
            onClick: () => {
                window.open(`${SMR_URL}/${booking.smrToken}?or=${ORIGIN}&uid=${userID}`);
            },
        },
        {
            children: 'Set Hack',
            onClick: () => {
                navigate(`/v2/booking?id=${booking._id}`);
            },
        },
        {
            children: 'Restart Search',
            onClick: () => {
                dispatch(setRestartSearchId(booking));
            },
        },
        {
            children: 'Cancel service',
            onClick: () => {
                dispatch(openModal(ModalTypes.CancelService, booking));
            },
        },
        {
            children: 'Report Issue',
            onClick: () => {
                dispatch(openModal(ModalTypes.ReportIssue, booking));
            },
        },
        {
            children: 'Add comment',
            onClick: () => {
                dispatch(setRidesAddComment(booking));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};
