import React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Bookings } from '../screens/Bookings';
import { DriverDetailsScreen } from '../screens/DriverDetails';
import { FleetDetails } from '../screens/FleetDetails';
import { Reports } from '../screens/Reports';
import { SmallFleet } from '../screens/SmallFleet';
import { SmallFleetDetails } from '../screens/SmallFleetDetails';
import { WarningServiceRequests } from '../screens/WarningServiceRequests';
import { Workspaces } from '../screens/Workspaces';
import { WorkspaceDetails } from '../screens/WorkspaceDetails';
import { WorkspacesNotifications } from '../screens/WorkspacesNotifications';
import Receivables from '../screens/Receivables';
import CashDelivery from '../screens/CashDelivery';
import Shield from '../screens/Shield';
import Objections from '../screens/Objections';
import IssueDetail from '../screens/IssueDetail';
import DispersionDetail from '../screens/DispersionDetail';
import Business from '../screens/Business';
import CommandCenter from '../screens/CommandCenter';
import { AddRegion } from '../screens/AddRegion';
import { DeliveryProvidersAll } from '../screens/DeliveryProvidersAll';
import { BusinessDetails } from '../screens/BusinessDetails';
import TiersConfiguration from '../screens/TiersConfiguration';
import { DeliveryProviderDetail } from '../screens/DeliveryProviderDetail';
import { Booking } from '../screens/Booking';
import Plans from '../screens/Plans';
import { Concepts } from '../screens/Plans/Concepts';
import ExpressDeliveries from '../screens/ExpressDeliveries';
import AlertsScreen from '../screens/Alerts';
import UsersRouter from './users.route';
import Loading from '../widgets/Loading';
import Summary from '../screens/Summary';
import MonitorScreen from '../screens/TPDMonitor';
import PickerChat from '../screens/PickerChat';
import InvoicesRouter from './invoices.router';
import WhatsappCertificationsRouter from './WhatsappCertifications.router';
import IssuesRouter from './issues.router';
import FeaturesMonitors from '../screens/FeaturesMonitors';
import GuideDetails from '../screens/GuideDetails';

const ProtectedRoutes = () => {
    const accessToken = window.localStorage.getItem('token');
    const auth = useSelector((state: RootStateOrAny) => state.auth);

    if (!accessToken) return <Navigate to="/v2/login" />;
    if (!auth._id) return <Loading />;

    return (
        <Routes>
            <Route path="/" element={(<Summary />)} />
            <Route path="chat" element={(<PickerChat />)} />
            <Route path="booking" element={(<Booking />)} />
            <Route path="booking/:id/monitor" element={(<MonitorScreen />)} />
            <Route path="bookings" element={(<Bookings />)} />
            <Route path="workspace-notifications" element={(<WorkspacesNotifications />)} />
            <Route path="reports" element={(<Reports />)} />
            <Route path="workspaces" element={(<Workspaces />)} />
            <Route path="workspaces/:id" element={(<WorkspaceDetails />)} />
            <Route path="warning-service-requests" element={(<WarningServiceRequests />)} />
            <Route path="driver-details/:id" element={(<DriverDetailsScreen />)} />
            <Route path="small-fleet" element={(<SmallFleet />)} />
            <Route path="small-fleet/:id" element={(<SmallFleetDetails />)} />
            <Route path="small-fleet/:smallFleetId/:id" element={(<FleetDetails />)} />
            <Route path="receivables" element={(<Receivables />)} />
            <Route path="cash-delivery" element={(<CashDelivery />)} />
            <Route path="cash-delivery/:id" element={(<DispersionDetail />)} />
            <Route path="booking-issues" element={(<Shield />)} />
            <Route path="booking-issues/:id" element={(<IssueDetail />)} />
            <Route path="objected-requests" element={(<Objections />)} />
            <Route path="objected-requests/:id" element={(<Objections />)} />
            <Route path="business" element={(<Business />)} />
            <Route path="business/:id" element={(<BusinessDetails />)} />
            <Route path="command-center" element={(<CommandCenter />)} />
            <Route path="tiers-configuration" element={(<TiersConfiguration />)} />
            <Route path="regions">
                <Route path="add" element={(<AddRegion />)} />
            </Route>
            <Route path="delivery-providers">
                <Route path="" element={(<DeliveryProvidersAll />)} />
                <Route path=":id" element={(<DeliveryProviderDetail />)} />
            </Route>
            <Route path="plans">
                <Route path="" element={(<Plans />)} />
                <Route path=":id" element={(<Concepts />)} />
            </Route>
            <Route path="express-delivery" element={(<ExpressDeliveries />)} />
            <Route path="express-delivery/:id" element={(<ExpressDeliveries />)} />
            <Route path="guide/:guideID" element={(<GuideDetails />)} />
            <Route path="alerts" element={(<AlertsScreen />)} />
            <Route path="users/*" element={<UsersRouter />} />
            <Route path="invoices/*" element={<InvoicesRouter />} />
            <Route path="whatsapp-certifications/*" element={<WhatsappCertificationsRouter />} />
            <Route path="issues/*" element={<IssuesRouter />} />
            <Route path="features-monitor" element={(<FeaturesMonitors />)} />
        </Routes>
    );
};

export default ProtectedRoutes;
