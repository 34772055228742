import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';

export const getInvoices = ({
    limit,
    page,
    status,
    startDate,
    endDate,
    search,
    invoiceId,
    frequency,
    gateway,
    type,
}: any) => {
    const params = new URLSearchParams({
        limit: limit.toString(),
        page: page.toString(),
    });

    (status && status.length) && params.append('status', JSON.stringify(status));
    (startDate && startDate.length) && params.append('startDate', startDate.toString());
    (endDate && endDate.length) && params.append('endDate', endDate.toString());
    (search && search.length) && params.append('search', search);
    (invoiceId && invoiceId.length) && params.append('invoiceId', invoiceId.toString());
    (frequency && frequency.length) && params.append('frequency', JSON.stringify(frequency));
    (gateway && gateway.length) && params.append('gateway', JSON.stringify(gateway));
    (type && type.length) && params.append('type', JSON.stringify(type));

    const url = `${OCTOPUS_BASE_URL}/invoices`;
    const response = axios.get(url, { params });
    return response;
};

export const getInvoice = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${id}`;
    const response = axios.get(url);
    return response;
};

export const updateInvoice = (id: string, data: any) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${id}`;
    const response = axios.put(url, data);
    return response;
};

export const deleteInvoice = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${id}`;
    const response = axios.delete(url);
    return response;
};

export const bulkTransactions = (transactions: any[]) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/transactions/bulk`;
    const response = axios.post(url, { transactions });
    return response;
};

export const bulkInvoices = (invoiceIds: any[], status: string) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/bulk`;
    const payload = {
        invoiceIds,
        status,
    };
    const response = axios.put(url, payload);
    return response;
};
