import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IPayPayload, ICreatePaymentPayload } from '../interfaces/payment.interface';
import { OctopusPaginatorQuery } from '../types/paginator';

export const pay = (payload: IPayPayload) => axios.post(`${OCTOPUS_BASE_URL}/payments/pay`, payload);

export const createPayment = (payload: ICreatePaymentPayload) => axios.post(`${OCTOPUS_BASE_URL}/payments`, payload);

export const getPayments = (paginator: OctopusPaginatorQuery = {}, currentStatus?: string[]) => {
    const params = new URLSearchParams();
    params.append('limit', paginator.limit!.toString());
    params.append('page', paginator.page!.toString());
    if (currentStatus?.length) {
        params.append('status', JSON.stringify(currentStatus));
    }
    const url = `${OCTOPUS_BASE_URL}/payments`;
    return axios.get(url, { params });
};

export const getTransactions = (paymentId: string) => axios.get(`${OCTOPUS_BASE_URL}/payments/${paymentId}`);

export const getConcepts = (planID: string) => {
    const url = `${OCTOPUS_BASE_URL}/payments/plans/${planID}/concepts`;
    return axios.get(url);
};

export const getAccountingPlans = ({ paginator, search }: any) => {
    const query: any = {
        ...paginator,
    };

    search && (query.search = search);

    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/payments/plans?${urlParams.toString()}`;
    return axios.get(url);
};

export const addPlan = (payload: any) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/`, payload)
);

export const updateConcept = (conceptId: string, payload: any) => (
    axios.patch(`${OCTOPUS_BASE_URL}/payments/plans/${payload.planID}/concepts/${conceptId}`, payload)
);

export const addConcept = (payload: any) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/${payload.planID}/concepts/`, payload)
);

export const testConcept = (planId: string, conceptId: string, payload: IPayPayload) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/${planId}/concepts/${conceptId}/test`, payload)
);

export const testPlan = (planId: string, payload: IPayPayload) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/${planId}/test`, payload)
);

export const getTransactionConcepts = (transactionID: string) => {
    const url = `${OCTOPUS_BASE_URL}/payments/transactions/${transactionID}`;
    return axios.get(url);
};

export const duplicatePlan = (planID: string) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/${planID}/duplicate`)
);

export const deletePlan = (plan: string) => (
    axios.delete(`${OCTOPUS_BASE_URL}/payments/plans/${plan}`)
);

export const getWorkspacesByPlan = (plan: string) => (
    axios.get(`${OCTOPUS_BASE_URL}/payments/plans/${plan}/workspaces`)
);

export const getPlanVariables = (planID: string) => {
    const url = `${OCTOPUS_BASE_URL}/payments/plans/${planID}/variables`;
    return axios.get(url);
};

export const getVariables = () => {
    const url = `${OCTOPUS_BASE_URL}/payments/plans/variables`;
    return axios.get(url);
};

export const addVariable = (payload: any) => (
    axios.post(`${OCTOPUS_BASE_URL}/payments/plans/variables/`, payload)
);

export const updateVariable = (variableId: string, payload: any) => (
    axios.patch(`${OCTOPUS_BASE_URL}/payments/plans/variables/${variableId}`, payload)
);

export const updatePlan = (planId: string, payload: any) => (
    axios.patch(`${OCTOPUS_BASE_URL}/payments/plans/${planId}`, payload)
);

export const deleteConcept = (planId: string, conceptId: string) => {
    const url = `${OCTOPUS_BASE_URL}/payments/plans/${planId}/concepts/${conceptId}`;
    return axios.delete(url);
};

export const deleteTransaction = (transactionId: string) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/transactions/${transactionId}`;
    return axios.delete(url);
};

export const getMRRTransactions = (invoiceId: string) => axios.get(`${OCTOPUS_BASE_URL}/invoices/${invoiceId}/mrr-transactions`);
