import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    notification,
    PageHeader,
    Table,
    Typography,
} from 'antd';
import { IBusiness } from '../../../types/bussiness';
import { PaginatorType } from '../../../types/paginator';
import { getBusinesses } from '../../../api/business';

interface Props {
    invoiceId: string,
    businessIds: string[],
}

const { Title, Text } = Typography;

const Businesses = ({ invoiceId, businessIds }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [businesses, setBusinesses] = useState<IBusiness[]>([]);
    const [paginator, setPaginator] = useState<PaginatorType>({
        limit: 20,
        skip: 1,
        ids: businessIds,
    });
    const [total, setTotal] = useState<number>(0);

    const columns: any[] = [
        {
            title: 'Unique ID',
            dataIndex: 'userUniqueID',
            key: 'userUniqueID',
            align: 'center' as 'center',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {moment(item.createdAt).format('ddd DD MMM')}
                </Text>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {item.businessID.companyName || '-'}
                </Text>
            ),
        },
        {
            title: 'Contact Email',
            dataIndex: 'email',
            key: 'email',
            align: 'center' as 'center',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {item.businessID.companyFullMobile || '-'}
                </Text>
            ),
        },
    ];

    const fetchBusinesses = () => {
        setLoading(true);

        getBusinesses(paginator)
            .then((response: any) => {
                setBusinesses(response.data.data);
                setTotal(response.data.meta.itemCount);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || 'Error fetching businesses';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchBusinesses();
    }, [invoiceId, paginator.skip, paginator.limit]);

    return (
        <div>
            <PageHeader
                title={(
                    <Title level={5}>
                        Businesses associated with this invoice
                    </Title>
                )}
                subTitle={(
                    total > 20 && (
                        <Text>
                            This invoice has more than 20 businesses associated with it. Showing the first 20 businesses.
                        </Text>
                    )
                )}
            />
            <Table
                columns={columns}
                dataSource={businesses}
                loading={loading}
                rowKey="_id"
                style={{ marginLeft: 20 }}
            />
        </div>
    );
};

export default Businesses;
