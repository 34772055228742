import { Form, Select } from 'antd';
import { IInvoice } from '../context/invoices.interface';
import { InvoicesStatus } from '../../../const/invoices';

interface Props {
    form: any;
    multiple?: boolean;
    item?: IInvoice;
}

const ChangeStatus = ({
    form,
    item,
    multiple = false,
}: Props) => {
    const STATUS = Object.entries(InvoicesStatus).map(([key, value]) => ({
        label: value,
        value: key,
    }));

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={() => {}}
            initialValues={{ status: multiple ? item?.status : undefined }}
        >
            <Form.Item
                name="status"
                label="Select the new status of the invoice"
                rules={[{ required: true, message: 'Please select the status' }]}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    options={STATUS}
                    onChange={(value: string) => {}}
                />
            </Form.Item>
        </Form>
    );
};

export default ChangeStatus;
