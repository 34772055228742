/* eslint-disable prefer-destructuring */
import { notification } from 'antd';
import { get } from 'lodash';
import {
    createContext, FC, useEffect, useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IGuide } from '../../../interfaces/express-delivery.interface';
import { IContext } from './guides.interface';
import { initialContext } from './guides.initial';
import { getGuides, getGuide } from '../../../api/express-delivery';
import { socketInstance } from '../../../context/SocketClientContext';
import socketEvents from '../../../const/socketEvent';
import { ISocketPayloadGuide } from '../../../interfaces/sockets.interface';
import { SocketActions } from '../../../const/socket-actions';

export const GuidesContext = createContext<IContext>(initialContext);

export const GuidesProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<IGuide | null>(null);
    const [data, setData] = useState<IGuide[]>(initialContext.data);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const [searchParams, setSearchParams] = useSearchParams();

    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);
    const startDate = searchParams.get('startDate') || initialContext.startDate;
    const endDate = searchParams.get('endDate') || initialContext.endDate;
    const tab = searchParams.get('tab') || initialContext.tab;

    const location = useLocation();

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = status);
        params.startDate = startDate;
        params.endDate = endDate;
        params.tab = tab;
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            skip: (page - 1) * pageSize,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();
        value.length === 0 ? delete params.status : (params.status = JSON.stringify(value));
        setSearchParams(params);
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        date[0] !== initialContext.startDate && (params.startDate = date[0]);
        date[1] !== initialContext.endDate && (params.endDate = date[1]);
        setSearchParams(params);
    };

    const fetchData = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        getGuides(params)
            .then(({ data: { data: response } }: any) => {
                const { data, total } = response;
                data.sort((
                    a: { createdAt: string | number | Date; },
                    b: { createdAt: string | number | Date; },
                ) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setData(data);
                setTotal(total);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = (id: string) => {
        setLoadingAction(true);

        getGuide(id)
            .then(({ data: { data } }: any) => setItemSelected(data))
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    useEffect(() => {
        const guideID = location.pathname.split('/')[3];
        if (itemSelected && guideID) {
            fetchItem(guideID);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!socketInstance) return;

        const updateData = (guideId: string, updates: Partial<IGuide>) => {
            setData((prevData) => prevData.map((item) => (item._id === guideId ? { ...item, ...updates } : item)));
        };

        const handleNotification = ({ payload }: ISocketPayloadGuide) => {
            const { data: socketData } = payload;

            switch (socketData.action) {
            case SocketActions.GUIDE_CREATE: {
                const exists = data.some((item) => item._id === socketData.guideId);
                if (!exists) {
                    setData((prevData) => [socketData as unknown as IGuide, ...prevData]);
                }
                break;
            }
            case SocketActions.GUIDE_UPDATE:
                updateData(socketData.guideId, { status: socketData.status });
                break;
            case SocketActions.EXPRESS_DELIVERY_UPDATE:
                updateData(socketData.guideId, { status: socketData.status });
                break;
            default:
                console.warn(`Unhandled socket action: ${socketData.action}`);
            }
        };

        socketInstance.on(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);

        return () => {
            socketInstance.off(socketEvents.NOTIFICATION_SEND_TO_ADMIN, handleNotification);
        };
    }, [socketInstance, data]);

    return (
        <GuidesContext.Provider
            value={{
                loading,
                loadingAction,
                data,
                itemSelected,
                total,
                paginator,
                startDate,
                endDate,
                searchParams,
                setLoading,
                fetchData,
                onPageChange,
                handleStatus,
                handleDate,
                setItemSelected,
            }}
        >
            {children}
        </GuidesContext.Provider>
    );
};
