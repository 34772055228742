import { FC, useEffect, useCallback } from 'react';
import { Select } from 'antd';

export interface SelectColumnsOption {
    value: string;
    label: string;
}

export interface SelectRenderColumnsProps {
    options: SelectColumnsOption[];
    value: string[];
    localStorageName: string;
    placeholder?: string;
    setColumns: (values: string[]) => void;
}

const SelectRenderColumns: FC<SelectRenderColumnsProps> = ({
    options,
    value,
    localStorageName,
    placeholder = 'Select Columns',
    setColumns,
}) => {
    const handleOnChange = useCallback(
        (selectedValues: string[]) => {
            setColumns(selectedValues);
            localStorage.setItem(localStorageName, JSON.stringify(selectedValues));
        },
        [localStorageName],
    );

    useEffect(() => {
        const storedValues = localStorage.getItem(localStorageName);
        if (storedValues) {
            setColumns(JSON.parse(storedValues));
        }
    }, [localStorageName]);

    return (
        <Select
            mode="multiple"
            style={{ width: 300 }}
            placeholder={placeholder}
            onChange={handleOnChange}
            options={options}
            maxTagCount="responsive"
            value={value}
        />
    );
};

export default SelectRenderColumns;
