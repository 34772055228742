import { EInvoiceStatus } from '../enums/invoices.enum';

export const InvoicesStatus = {
    [EInvoiceStatus.PENDING]: 'Pending',
    [EInvoiceStatus.CLOSED]: 'Closed',
    [EInvoiceStatus.ACTIVE]: 'Active',
    [EInvoiceStatus.PAYED]: 'Payed',
    [EInvoiceStatus.EXPIRED]: 'Expired',
    [EInvoiceStatus.UNCOLLECTIBLE]: 'Uncollectible',
};
