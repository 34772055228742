import { useContext, useEffect, useState } from 'react';
import { Button, PageHeader, Space } from 'antd';
import { Filters, InvoicesTable } from './components';
import { InvoicesContext } from './context/invoices.context';
import { IInvoice } from './context/invoices.interface';
import { TransactionsModal } from './components/TransactionsModal';
import PaymentTransactions from '../Modals/PaymentTransactions';
import MRRTransactions from '../Modals/MRRTransactions';

const Invoices = ({ params }: { params: URLSearchParams }) => {
    const {
        fetchData,
        loading,
        loadingAction,
        data,
        item,
        total,
        paginator,
        onPageChange,
        searchParams,
        date,
        selectedRowKeys,
        setItem,
        setData,
        handleDate,
        handleSearch,
        handleInvoiceId,
        handleFrequency,
        handleGateway,
        handleStatus,
        handleType,
        setSelectedRowKeys,
    } = useContext(InvoicesContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchData();
    }, [params, paginator]);

    const updateInvoice = (invoice: IInvoice) => {
        setData((invoices) => {
            const data = invoices.map((i) => {
                if (i._id === invoice._id) {
                    return invoice;
                }
                return i;
            });
            return data;
        });
    };

    return (
        <PageHeader
            title="Invoices"
            style={{ padding: '32px 24px 24px 0' }}
            extra={(
                <Button
                    type="primary"
                    onClick={() => setShowModal(true)}
                >
                    Bulk Transactions
                </Button>
            )}
            footer={(
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    {
                        item?.type === 'MRR' ? (
                            <MRRTransactions
                                invoice={item}
                                onClose={(invoice: IInvoice | null) => {
                                    if (invoice) {
                                        updateInvoice(invoice);
                                    }
                                    setItem(null);
                                }}
                            />
                        ) : (
                            <PaymentTransactions
                                payment={item}
                                onClose={(invoice: IInvoice | null) => {
                                    if (invoice) {
                                        updateInvoice(invoice);
                                    }
                                    setItem(null);
                                }}
                            />
                        )
                    }

                    <Filters
                        searchParams={searchParams}
                        date={date}
                        handleDate={handleDate}
                        handleStatus={handleStatus}
                        handleSearch={handleSearch}
                        handleFrequency={handleFrequency}
                        handleGateway={handleGateway}
                        handleInvoiceId={handleInvoiceId}
                        handleType={handleType}
                    />

                    <InvoicesTable
                        loading={loading}
                        data={data}
                        total={total}
                        paginator={paginator}
                        onPageChange={onPageChange}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                    />
                    <TransactionsModal visible={showModal} onCancel={() => setShowModal(false)} />
                </Space>
            )}
        />
    );
};

export default Invoices;
