import GuideDetail from '../components/GuideDetails/components/GuideDetail';
import { GuideDetailsProvider } from '../components/GuideDetails/context/guide-details.context';

const GuideDetails = () => (
    <GuideDetailsProvider>
        <GuideDetail />
    </GuideDetailsProvider>
);

export default GuideDetails;
