/* eslint-disable prefer-destructuring */
import { notification } from 'antd';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IContext, IInvoice } from './invoices.interface';
import { initialContext } from './invoices.initial';
import { getInvoice, getInvoices } from '../../../api/invoices';
import { HEADERS, parseData } from '../../../utils/userReport';
import { FileType } from '../../../types/reports';

export const InvoicesContext = createContext<IContext>(initialContext);

export const InvoicesProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAction, setLoadingAction] = useState<boolean>(initialContext.loadingAction);
    const [data, setData] = useState<IInvoice[]>(initialContext.data);
    const [item, setItem] = useState<IInvoice | null>(initialContext.item);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams?.get('search') || '';
    const invoiceId = searchParams?.get('invoiceId') || '';
    const status = JSON.parse(searchParams?.get('status') || '[]');
    const frequency = JSON.parse(searchParams?.get('frequency') || '[]');
    const type = JSON.parse(searchParams?.get('type') || '[]');
    const gateway = JSON.parse(searchParams?.get('gateway') || '[]');
    const date = searchParams.get('date') || null;

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = status);
        (frequency && frequency.length) && (params.frequency = frequency);
        (type && type.length) && (params.type = type);
        (gateway && gateway.length) && (params.gateway = gateway);
        date && (params.date = date);
        search && (params.search = search);
        invoiceId && (params.invoiceId = invoiceId);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleStatus = (value: string[]) => {
        value.length === 0 ? searchParams.delete('status') : searchParams.set('status', JSON.stringify(value));
        setSearchParams(searchParams);
    };

    const handleFrequency = (value: string[]) => {
        value.length === 0 ? searchParams.delete('frequency') : searchParams.set('frequency', JSON.stringify(value));
        setSearchParams(searchParams);
    };

    const handleType = (value: any) => {
        value.length === 0 ? searchParams.delete('type') : searchParams.set('type', JSON.stringify(value));
        setSearchParams(searchParams);
    };

    const handleGateway = (value: any) => {
        value.length === 0 ? searchParams.delete('gateway') : searchParams.set('gateway', JSON.stringify(value));
        setSearchParams(searchParams);
    };

    const handleDate = (date: Moment) => {
        const dateString = date ? date.toISOString() : '';
        dateString ? searchParams.set('date', dateString) : searchParams.delete('date');
        setSearchParams(searchParams);
    };

    const handleSearch = (value: string) => {
        value ? searchParams.set('search', value) : searchParams.delete('search');
        setSearchParams(searchParams);
    };

    const handleInvoiceId = (value: string) => {
        value ? searchParams.set('invoiceId', value) : searchParams.delete('invoiceId');
        setSearchParams(searchParams);
    };

    const fetchData = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };
        if (params.date) {
            const startDate = moment(params.date).startOf('day').toISOString();
            const endDate = moment(params.date).endOf('day').toISOString();
            params.startDate = startDate;
            params.endDate = endDate;
            delete params.date;
        }

        getInvoices(params)
            .then(({ data: { data: response } }: any) => {
                const { data, total } = response;
                setData(data);
                setTotal(total);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = (id: string) => {
        setLoadingAction(true);

        getInvoice(id)
            .then(({ data: { data: response } }: any) => {
                setItem({
                    ...item,
                    ...response,
                });
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    const downloadInvoices = () => {
        const rows = data?.filter((row) => selectedRowKeys!.includes(row._id));
        parseData({
            rows,
            fileName: 'invoices',
            selectedRows: Object.keys(HEADERS['invoiceTransactions' as string]),
            headersType: 'invoiceTransactions',
            fileType: FileType.XLSX,
        });
    };

    const handleSelectedColumns = (value: string[]) => {
        setSelectedColumns(value);
    };

    useEffect(() => {
        if (!item) return;
        fetchItem(item._id);
    }, [item?._id]);

    return (
        <InvoicesContext.Provider
            value={{
                loading,
                loadingAction,
                data,
                item,
                total,
                paginator,
                date,
                searchParams,
                selectedRowKeys,
                selectedColumns,
                setLoading,
                setItem,
                setData,
                fetchData,
                fetchItem,
                onPageChange,
                handleStatus,
                handleFrequency,
                handleGateway,
                handleDate,
                handleSearch,
                handleInvoiceId,
                handleType,
                setSelectedRowKeys,
                downloadInvoices,
                handleSelectedColumns,
            }}
        >
            {children}
        </InvoicesContext.Provider>
    );
};
