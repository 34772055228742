const socketEvents = {
    BOOKING_SEND_TO_ADMIN: 'booking_send_to_admin',
    DISCONNECT: 'disconnect',
    CONNECT: 'connect',
    ADMIN_JOIN: 'event_join_admin',
    SEND_MESSAGE: 'SEND_MESSAGE',
    READ_MESSAGE: 'READ_MESSAGE',
    NOTIFICATION: 'NOTIFICATION',
    PICKER_CHAT_NEW_MESSAGE: 'picker-chat-new_message',
    PICKER_CHAT_JOIN_CHAT: 'picker-chat-join',
    PICKER_CHAT_LEAVE_CHAT: 'picker-chat-leave',
    NOTIFICATION_SEND_TO_ADMIN: 'notification_send_to_admin',

    EXPRESS_DELIVERY_CREATED: 'express_delivery_created',
    EXPRESS_DELIVERY_UPDATED: 'express_delivery_updated',
    GUIDE_CREATED: 'guide_created',
    GUIDE_UPDATED: 'guide_updated',
};

export default socketEvents;
