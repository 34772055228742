/* eslint-disable prefer-destructuring */
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IContext } from './issues.interface';
import { initialContext } from './issues.initial';
import { IIssue } from '../../../interfaces/issue.interface';
import { getBookingIssues, getIssueDetail } from '../../../api/issues';
import * as APIBooking from '../../../api/booking';

export const IssuesContext = createContext<IContext>(initialContext);

export const IssuesProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAction, setLoadingAction] = useState<boolean>(initialContext.loadingAction);
    const [data, setData] = useState<IIssue[]>(initialContext.data);
    const [item, setItem] = useState<IIssue | null>(initialContext.item);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const [searchParams, setSearchParams] = useSearchParams();

    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);
    const startDate = searchParams.get('startDate') || null;
    const endDate = searchParams.get('endDate') || null;
    const issueId = searchParams.get('issueId') || '';
    const numericId = searchParams.get('numericId');
    const workspaceId = searchParams.get('workspaceId');
    const hasNewMessages = searchParams.get('hasNewMessages');
    const type = searchParams.get('type');

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = JSON.stringify(status));
        startDate && (params.startDate = startDate);
        endDate && (params.endDate = endDate);
        issueId && (params.issuePublicID = issueId);
        numericId && (params.bookingNumericId = numericId);
        workspaceId && (params.workspaceUniqueID = workspaceId);
        hasNewMessages && (params.hasNewMessages = hasNewMessages);
        type && (params.type = type);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();
        value && value.length !== '' && (params.status = JSON.stringify(value));
        setSearchParams(params);
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        if (!date.length) {
            delete params.startDate;
            delete params.endDate;
            setSearchParams(params);
            return;
        }
        (params.startDate = date[0]);
        (params.endDate = date[1]);
        setSearchParams(params);
    };

    const handleIssueId = (value: string) => {
        setSearchParams({ ...getParams(), issueId: value });
    };

    const handleBooking = (value: string) => {
        setSearchParams({ ...getParams(), numericId: value });
    };

    const handleWorkspace = (value: string) => {
        setSearchParams({ ...getParams(), workspaceId: value });
    };

    const handleUnRead = (value: boolean) => {
        const { hasNewMessages, ...params } = getParams();
        if (!value) {
            delete params.hasNewMessages;
            setSearchParams(params);
            return;
        }
        setSearchParams({ ...params, hasNewMessages: value });
    };

    const handleType = (value: string) => {
        if (!value) {
            const { type, ...params } = getParams();
            setSearchParams(params);
            return;
        }
        setSearchParams({ ...getParams(), type: value });
    };

    const fetchData = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        getBookingIssues(params)
            .then((response: any) => {
                const { data: { data } } = response;
                setData(data.data);
                setTotal(data.total);
            })
            .catch((error: any) => {
                console.log({ error });
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = (id: string) => {
        setLoadingAction(true);

        const itemData = data.find((item) => item.issuePublicID === id);

        if (itemData) {
            setItem(itemData);

            APIBooking.getBookingDetails(itemData.bookingID).then((response: any) => {
                const { data } = response.data;
                setItem({ ...itemData, bookingData: data });
            })
                .catch((error: any) => {
                    console.log({ error });
                })
                .finally(() => setLoadingAction(false));
        }
    };

    return (
        <IssuesContext.Provider
            value={{
                loading,
                loadingAction,
                data,
                item,
                total,
                paginator,
                startDate,
                endDate,
                searchParams,
                setSearchParams,
                setLoading,
                setItem,
                setData,
                fetchData,
                fetchItem,
                onPageChange,
                handleStatus,
                handleDate,
                handleIssueId,
                handleBooking,
                handleWorkspace,
                handleType,
                handleUnRead,
            }}
        >
            {children}
        </IssuesContext.Provider>
    );
};
